import React, { Component } from 'react';
import LoadingState from '../global/loading-state';
import TitlePage from '../TitlePage';
import PageHeader from '../PageHeader';
import DetailedFeedbackChart from '../charts/detailedFeedbackChart';
import FreeTextFeedback from './FreeTextFeedback';
import DetailedFeedbackReflection from './DetailedFeedbackReflection';

export default class DetailedFeedbackSection extends Component {
  constructor(props) {
    super(props);
    this.upadteMultichoiceSectionsRerenderedForPDF = this.upadteMultichoiceSectionsRerenderedForPDF.bind(this);
    this.updateQuestionsRerenderedForPDF = this.updateQuestionsRerenderedForPDF.bind(this);
    this.state = {
      loading: true,
      multichoiceSectionsRerenderedForPDF: false,
      questionsRerenderedForPDF: false,
    };
  }

  componentDidMount() {
    const {sectionDetails, sectionsLegacyMode, sectionAveragesByRelationshipCurrent, secLengthNoFreeText, reportType, loading} = this.props

    const orderedSections = Object.keys(sectionDetails)
      .sort((a, b) => {
        if (sectionsLegacyMode) {
          return sectionDetails[a].created - sectionDetails[b].created
        } else {
          return sectionDetails[a].sortOrder - sectionDetails[b].sortOrder
        }
      })
    let freeTextQuestionIDs = {}
    let multiChoiceQuestionIDs = {}
    let numberOfSections = orderedSections.length
    let lastTextQuestionID
    let isLastSectionFreetext = false
    let lastMultichoiceSectionID

    orderedSections.forEach((sectionID, sectionIndex) => {
      const {questions} = sectionDetails[sectionID]
      // orders questions before separation
      const questionIDs = Object.keys(questions).sort((a,b) => {
        return questions[a].sortOrder - questions[b].sortOrder
      })
      let multiChoiceQuestionsCount = 0
      let freetextQuestionsCount = 0
      questionIDs.forEach((questionID, questionIndex) => {
        // separate questions by type
        const {answerType} = questions[questionID]
        if (answerType === 'FreeText') {
          freetextQuestionsCount++
          // Establish list of FreeText questions
          if (!freeTextQuestionIDs[sectionID]) {
            freeTextQuestionIDs[sectionID] = []
          }
          freeTextQuestionIDs[sectionID].push(questionID)
          lastTextQuestionID = questionID;
          if (numberOfSections === sectionIndex + 1 && reportType !== 'Group') {
            isLastSectionFreetext = true
          }
        } else if (answerType === 'MultiChoice') {
          multiChoiceQuestionsCount++
          // Establish list of MultiChoice questions
          if (!multiChoiceQuestionIDs[sectionID]) {
            multiChoiceQuestionIDs[sectionID] = []
          }
          multiChoiceQuestionIDs[sectionID].push(questionID)
          lastMultichoiceSectionID = sectionID;
        }
      })
    })
    this.setState({ orderedSections, freeTextQuestionIDs, multiChoiceQuestionIDs, lastTextQuestionID, lastMultichoiceSectionID, isLastSectionFreetext, loading});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isLastSectionFreetext) {
      if (prevState.questionsRerenderedForPDF !== this.state.questionsRerenderedForPDF) {
        this.props.updatePDFExportPreRenderStatus()
      }
    } else {
      if (prevState.multichoiceSectionsRerenderedForPDF !== this.state.multichoiceSectionsRerenderedForPDF) {
        this.props.updatePDFExportPreRenderStatus()
      }
    }
  }

  upadteMultichoiceSectionsRerenderedForPDF() {
    this.setState({ multichoiceSectionsRerenderedForPDF: true });
  }

  updateQuestionsRerenderedForPDF(questionID) {
    // once the final freetext question has re-rendered, the state is updated to trigger updatePDFExportPreRenderStatus on update check
    const {lastTextQuestionID} = this.state
    if (questionID === lastTextQuestionID) {
      this.setState({ questionsRerenderedForPDF: true });
    }
  }

  render() {
    const {loading, orderedSections, freeTextQuestionIDs, multiChoiceQuestionIDs, lastMultichoiceSectionID} = this.state
    const {reportType, sectionDetails, questionDetails, sectionAverageSelfCurrent, sectionResponsesSelfCurrent, sectionDistributionArrays, colours, previous360Scores, current360Relationships, dfSectionRelationshipKeys, relationshipKeys, sectionAveragesByRelationshipCurrent, sectionResponsesByRelationshipCurrent, sectionCombinedReviewersAverage, sectionCombinedReviewerResponses, questionDistributionArrays, questionAveragesSelfCurrent, questionResponsesSelfCurrent, questionCombinedReviewersAverage, questionCombinedReviewersResponses, questionAveragesByRelationshipCurrent, questionResponsesByRelationshipCurrent, userFreetextResponses, reviewerFreetextResponses, pdfRequested} = this.props

    if (!loading) {
      return (
        <>
          <TitlePage h1Text='Part 2:' h2Text={`${reportType === 'Team' ? 'Team' : 'Your'} Detailed Feedback`} />
          <div className='bg-white page-break user-report__content'>
            <PageHeader h2Class='mt4' h2Text='Part 2: ' strong='Step 1' h3Text='Navigating the detail' line={true} />

            {reportType === 'User' || reportType === 'Comparison' ? (
              <>
                <p>This section of the report looks in detail at the feedback that you have received in each competency area. It shows the average feedback rating from each of your rater groups (groups of reviewers, e.g., ‘direct reports’) for every statement (behaviour/action), along with your self-review rating.</p>
                <p>The statement ratings are also added together to show the headline ratings for each competency.</p>
                <p>Where you received close or similar ratings for statements from your reviewers, means that you are consistent when displaying that behaviour/action. Conversely, delivering consistently lowly-rated statements (behaviours/actions), can point to areas where you might need to change/improve.</p>
                <p>Statements where there is a big gap between the ratings provided by different rater groups (e.g., line manager compared to direct reports), suggests a mismatch between their experiences of working with you, or different perceptions. Take some time to consider why this may be the case? Why is one group of people seeing something that the others are not?</p>
                <p>The number of responses shows the range of ratings that have been provided within each rater group. You can use these numbers to identify where there might be a wide range of ratings from a specific rater group. A wide range of ratings may suggest you have an inconsistent approach within a specific rater group.</p>
                <p>Finally, you can also use this area of the report to help you identify any potential ‘outlier’ ratings.</p>
                <h3 className='f5 mt4'>
                  <strong>The bars show the following:</strong>
                </h3>
                <p>
                  &bull; The blue bar shows your self-review rating
                  <br />
                  &bull; The remaining bars depict the various rater groups; showing each of their average ratings
                  <br />
                </p>
              </>
            ) : (
              <></>
            )}

            {reportType === 'Group' && (
              <>
                <p>This section of the report looks in detail at the feedback that the group received in each competency area. It shows the average feedback rating from each of the rater groups (groups of reviewers, e.g., ‘direct reports’) for every statement (behaviour/action), along with the collective self-review rating.</p>
                <p>The statement ratings are also added together to show the headline ratings for each competency.</p>
                <p>Where there are close or similar ratings for statements from reviewers, this means that the group are consistent when displaying that behaviour/action. Conversely, delivering consistently low rated statements (behaviours/actions), can point to areas where the group might need to change/improve.</p>
                <p>Statements where there is a big gap between the ratings provided by different rater groups (e.g., line manager compared to direct reports), suggests a mismatch between their experiences of working with the group, or different perceptions.</p>
                <p>The number of responses shows the range of ratings that have been provided within each rater group. You can use these numbers to identify where there might be a wide range of ratings from a specific rater group. A wide range of ratings may suggest the group has an inconsistent approach within a specific rater group.</p>
                <p>Finally, you can also use this area of the report to help you to identify any potential ‘outlier’ ratings.</p>
                <h3 className='f5 mt4'>
                  <strong>The bars show the following:</strong>
                </h3>
                <p>
                  &bull; The blue bar shows your self-review rating
                  <br />
                  &bull; The remaining bars depict the various rater groups; showing each of their average ratings
                  <br />
                </p>
              </>
            )}

            {reportType === 'Self' && (
              <p>This section of the report looks in detail at each of your self-report competency areas.</p>
            )}

            {reportType === 'Team' && (
              <>
                <p>This section of the report looks in detail at the feedback that the team has received in each competency area. It shows the average feedback rating from each team member for every statement (behaviour/action).</p>
                <p>The statement ratings are also added together to show the headline ratings for each competency.</p>
                <p>Where the team received higher ratings for statements, it means that it is consistent when displaying that behaviour/action. Conversely, delivering consistently lowly-rated statements (behaviours/actions), can point to areas where the team might need to change/improve.</p>
                <p>You can also use this area of the report to help you identify any potential ‘outlier’ ratings.</p>
              </>
            )}
          </div>

          {orderedSections.map((sectionID, i) => {
            const stepNumber = i + 2;
            let freetextQuestionsCount
            if (freeTextQuestionIDs[sectionID]) {
              freetextQuestionsCount = freeTextQuestionIDs[sectionID].length
            }
            let multichoiceQuestionsCount
            if (multiChoiceQuestionIDs[sectionID]) {
              multichoiceQuestionsCount = multiChoiceQuestionIDs[sectionID].length
            }
            return (
              <div key={sectionID} className={`${reportType === 'Team' && i === 0 && !freetextQuestionsCount ? 'page-break' : ''}`}>
                {/* Multichoice Chart */}
                {multichoiceQuestionsCount && (
                  <DetailedFeedbackChart
                    reportType={reportType} 
                    sectionID={sectionID}
                    stepNumber={stepNumber}
                    sectionDetails={sectionDetails[sectionID]}
                    multiChoiceQuestionIDs={multiChoiceQuestionIDs[sectionID]}
                    questionDetails={questionDetails}
                    sectionAverageSelfCurrent={sectionAverageSelfCurrent ? sectionAverageSelfCurrent[sectionID] : undefined}
                    sectionResponsesSelfCurrent={sectionResponsesSelfCurrent ? sectionResponsesSelfCurrent[sectionID] : undefined}
                    sectionDistributionArray={sectionDistributionArrays ? sectionDistributionArrays[sectionID] : undefined}
                    colours={colours}
                    sectionAverageSelfPrevious={previous360Scores ? previous360Scores.userSectionAverage[sectionID] : undefined}
                    sectionResponsesSelfPrevious={previous360Scores ? previous360Scores.userSectionCount[sectionID] : undefined}
                    dfSectionRelationshipKeys={dfSectionRelationshipKeys}
                    relationshipKeys={relationshipKeys}
                    current360Relationships={current360Relationships}
                    sectionAveragesByRelationshipCurrent={sectionAveragesByRelationshipCurrent}
                    sectionResponsesByRelationshipCurrent={sectionResponsesByRelationshipCurrent}
                    sectionAveragesByRelationshipPrevious={previous360Scores ? previous360Scores.averageBySections : undefined}
                    sectionResponsesByRelationshipPrevious={previous360Scores ? previous360Scores.sectionAnswerCount : undefined}
                    sectionCombinedReviewersAverage={sectionCombinedReviewersAverage ? sectionCombinedReviewersAverage[sectionID] : undefined}
                    sectionCombinedReviewerResponses={sectionCombinedReviewerResponses ? sectionCombinedReviewerResponses[sectionID] : undefined}
                    questionDistributionArray={questionDistributionArrays}
                    questionAveragesSelfCurrent={questionAveragesSelfCurrent}
                    questionResponsesSelfCurrent={questionResponsesSelfCurrent ? questionResponsesSelfCurrent[sectionID] : undefined}
                    questionAverageSelfPrevious={previous360Scores ? previous360Scores.userAnswers[sectionID].answers : undefined}
                    questionResponsesSelfPrevious={previous360Scores ? previous360Scores.userQuestionCount[sectionID] : undefined}
                    questionCombinedReviewersAverage={questionCombinedReviewersAverage ? questionCombinedReviewersAverage : undefined}
                    questionCombinedReviewersResponses={questionCombinedReviewersResponses ? questionCombinedReviewersResponses : undefined}
                    questionAveragesByRelationshipCurrent={questionAveragesByRelationshipCurrent}
                    questionResponsesByRelationshipCurrent={questionResponsesByRelationshipCurrent}
                    questionAveragesByRelationshipPrevious={previous360Scores ? previous360Scores.averageByQuestion : undefined}
                    questionResponsesByRelationshipPrevious={previous360Scores ? previous360Scores.reviewerAnswerCount : undefined}
                    upadteMultichoiceSectionsRerenderedForPDF={this.upadteMultichoiceSectionsRerenderedForPDF}
                    lastMultichoiceSectionID={lastMultichoiceSectionID}
                    pdfRequested={pdfRequested}
                  />
                )}

                {/* Freetext Responses */}
                {reportType !== 'Group' && freetextQuestionsCount && 
                  (
                    <FreeTextFeedback
                      reportType={reportType}
                      stepNumber={stepNumber}
                      freeTextQuestionIDs={freeTextQuestionIDs[sectionID]}
                      questionDetails={questionDetails}
                      relationshipKeys={relationshipKeys} 
                      current360Relationships={current360Relationships}
                      userFreetextResponses={userFreetextResponses ? userFreetextResponses[sectionID] : undefined}
                      reviewerFreetextResponses={reviewerFreetextResponses ? reviewerFreetextResponses[sectionID] : undefined}
                      pdfRequested={pdfRequested}
                      updateQuestionsRerenderedForPDF={this.updateQuestionsRerenderedForPDF}
                    />
                  )
                }

                {/* Reflection */}
                {reportType === 'Group' || reportType === 'Team' ? (
                  <></>
                ) : (
                  <DetailedFeedbackReflection stepNumber={stepNumber} />
                )}
              </div>
            )
          })}
        </>
      )
    } else {
      return <LoadingState />
    }
  }
}

