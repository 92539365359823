import React, { Component } from 'react';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import Dropdown from './global/dropdown';
import Modal from './global/modal';
import { lightBoxStyles } from '../constants/lightbox.js';

const alternateActions = [
  {
    id: 0,
    title: 'Compare Data',
    selected: false,
    key: 'alternateAction',
  },
  {
    id: 1,
    title: 'Archive',
    selected: false,
    key: 'alternateAction',
  },
  {
    id: 2,
    title: 'Delete',
    selected: false,
    key: 'alternateAction',
  },
  {
    id: 3,
    title: 'Edit',
    selected: false,
    key: 'alternateAction',
  },
];

const archivedActions = [
  {
    id: 0,
    title: 'Unarchive',
    selected: false,
    key: 'archivedActions',
  },
  {
    id: 1,
    title: 'Delete',
    selected: false,
    key: 'archivedActions',
  },
];

class List360 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      deleting: false,
      deleted: false,
      editing: false,
      unarchiving: false,
      showDuplicationModal: false,
      current360: this.props.current360,
    };
    this.delete360 = this.delete360.bind(this);
    this.confirmDeletion = this.confirmDeletion.bind(this);
    this.editProject = this.editProject.bind(this);
    this.confirmEdit = this.confirmEdit.bind(this);
    this.confirmUnarchive = this.confirmUnarchive.bind(this);
    this.doDuplicate360 = this.doDuplicate360.bind(this);
    this.archive360 = this.archive360.bind(this);
    this.unarchive360 = this.unarchive360.bind(this);
    this.confirmArchive = this.confirmArchive.bind(this);
    this.fireAlternateAction = this.fireAlternateAction.bind(this);
    this.fireArchivedAction = this.fireArchivedAction.bind(this);
  }
  // componentDidMount() {
  //   db.doGetCurrent360Groups(this.props.accountId, this.props.current360).then((snapshot) => {
  //     let groups = {}
  //     snapshot.docs.map((doc, i) => {
  //       groups[doc.id] = doc.data()
  //     })
  //
  //     this.setState({
  //       current360Groups: groups,
  //       loaded: true,
  //     })
  //   })
  // }
  delete360(e) {
    this.setState({
      deleting: true,
    });
  }
  confirmDeletion(e) {
    db.doDelete360(this.props.accountId, this.props.index).then(() => {
      this.setState({
        deleting: false,
        deleted: true,
      });
    });
    e.preventDefault();
  }
  editProject(e) {
    this.setState({
      editing: true,
    });
  }
  confirmEdit(e) {
    db.doUpdateProject(this.props.accountId, this.props.index, this.title.value);
    let projectData = this.props.current360;
    projectData.title = this.title.value;

    this.setState({
      current360: projectData,
      editing: false,
    });
    e.preventDefault();
  }
  doDuplicate360(e) {
    // console.log(this.props.duplicate360);
    this.props.duplicate360(this.props.accountId, this.title.value, this.props.index, true);
  }
  archive360() {
    this.setState({
      archiving: true,
    });
  }
  unarchive360() {
    this.setState({
      unarchiving: true,
    });
  }
  confirmArchive(e) {
    db.doArchive360(this.props.accountId, this.props.index).then(() => {
      this.setState({ archiving: false });
      window.location.reload();
    });

    e.preventDefault();
  }
  confirmUnarchive(e) {
    db.doUnarchive360(this.props.accountId, this.props.index).then(() => {
      this.setState({ Unarchiving: false });
      window.location.reload();
    });

    e.preventDefault();
  }
  fireAlternateAction(id, key) {
    let temp = JSON.parse(JSON.stringify(alternateActions));
    temp.forEach((item) => (item.selected = false));
    temp[id].selected = true;

    switch (id) {
      case 0:
        this.setState({ showDuplicationModal: true });
        break;
      case 1:
        this.archive360();
        break;
      case 2:
        this.delete360();
        break;
      case 3:
        this.editProject();
        break;
      default:
        return;
    }
  }

  fireArchivedAction(id, key) {
    let temp = JSON.parse(JSON.stringify(archivedActions));
    temp.forEach((item) => (item.selected = false));
    temp[id].selected = true;

    switch (id) {
      case 0:
        this.setState({
          unarchiving: true,
        });
        break;
      case 1:
        this.delete360();
        break;
      default:
        return;
    }
  }
  render() {
    const { index, accountId, userDetails, accountTitle, current360Groups, current360Relationships } = this.props;
    const current360 = this.state.current360;

    // let createdDate = current360.createdDate
    var createdDate = new Date(current360.createdDate);
    var createdDay = createdDate.getDate();
    var createdMonth = createdDate.getMonth();
    var createdYear = createdDate.getFullYear();

    let numActiveGroups = 0;
    let numIncompleteGroups = 0;
    let numCompletedGroups = 0;

    let editLink = `/dashboard/${accountId}/360s/${index}/edit/`;

    if (current360Groups) {
      let numGroups = Object.keys(current360Groups).length;
      let numGroupsText = Object.keys(current360Groups).length > 1 ? 's' : '';

      Object.keys(current360Groups).map((key) => {
        // // console.log(current360Groups[key].statusCode)
        if (current360Groups[key].statusCode != null) {
          if (current360Groups[key].statusCode == 0) {
            numActiveGroups++;
          } else {
            numCompletedGroups++;
          }
        } else {
          numIncompleteGroups++;
        }
      });
    }

    let numActiveGroupsText = numActiveGroups > 1 ? 's' : '';
    let numIncompleteGroupsText = numIncompleteGroups > 1 ? 's' : '';
    let numCompletedGroupsText = numCompletedGroups > 1 ? 's' : '';

    let activeStatusText = `${numActiveGroups} active group${numActiveGroupsText}.${current360.statusCode == 1 ? ' Instructions not sent.' : ''}`;
    let incompleteStatusText = `${numIncompleteGroups} incomplete group${numIncompleteGroupsText}.`;
    let completedStatusText = `${numCompletedGroups} complete group${numCompletedGroupsText}.`;

    let statusText = `${numActiveGroups > 0 ? activeStatusText : 'No User groups setup'}
        ${numIncompleteGroups > 0 ? incompleteStatusText : ''}
          ${numCompletedGroups > 0 ? completedStatusText : ''}`;

    if (current360.statusCode === 0) {
      statusText = 'No valid questionnaire setup';
    }

    let updatedAlternateActions = [...alternateActions]

    if (this.props.current360.type === 'Team Assessment') {
      updatedAlternateActions.splice(0,1)
    }

    return (
      <div className={this.state.deleted ? 'dn' : ''}>
        <div className='individual-360-strip mv4'>
          <p className='reset-mar individual-360-strip__title'>
            <strong>{current360.title}</strong>
            <br />
            <br />
            {/*<strong>Status:</strong> { statusText }*/}
          </p>
          <div className='individual-360-strip__actions'>
            <div>
              <p className='reset-mar'>
                Created {createdDay}/{createdMonth + 1}/{createdYear}
              </p>
            </div>
            <div>
              {this.props.current360.archived ? (
                <Dropdown title='Archived actions' list={archivedActions} setTitleOnSelect={false} resetThenSet={this.fireArchivedAction} />
              ) : (
                <Dropdown title='Alternate actions' list={updatedAlternateActions} setTitleOnSelect={false} resetThenSet={this.fireAlternateAction} />
              )}
            </div>
            <div>
              <p className='reset-mar'>
                {this.props.current360.archived ? (
                  <Link
                    className='btn'
                    to={{
                      pathname: editLink,
                      current360: current360,
                      userDetails: userDetails,
                      accountTitle: accountTitle,
                      current360Groups: current360Groups,
                      current360Relationships: current360Relationships,
                    }}
                  >
                    View Project
                  </Link>
                ) : (
                  <Link
                    className='btn'
                    to={{
                      pathname: editLink,
                      current360: current360,
                      userDetails: userDetails,
                      accountTitle: accountTitle,
                      current360Groups: current360Groups,
                      current360Relationships: current360Relationships,
                    }}
                  >
                    Edit Project
                  </Link>
                )}
              </p>
            </div>
          </div>

          <Modal
            isOpen={this.state.deleting}
            contentLabel='Delete this 360'
            onClose={() => this.setState({ deleting: false })}
            content={
              <div>
                <h2>Are you sure you want to delete this {this.props.current360.type}?</h2>
                <p>Click confirm below to remove forever</p>
                <button onClick={this.confirmDeletion} className='btn'>
                  Confirm
                </button>
              </div>
            }
          />

          <Modal
            isOpen={this.state.showDuplicationModal}
            contentLabel='Duplicate this 360'
            onClose={() => this.setState({ showDuplicationModal: false })}
            content={
              <div>
                <h2>Are you sure you wish to compare the data from this report?</h2>
                <label htmlFor='title' className='f6 b db mb2'>
                  New Project Name
                </label>
                <input
                  required
                  ref={(input) => (this.title = input)}
                  aria-describedby='name-desc'
                  className='input-reset ba b--black-20 pa2 mb4 db w-100'
                  type='text'
                  placeholder='Give your project a name'
                />
                <button
                  onClick={(e) => {
                    this.doDuplicate360(e);
                  }}
                  className='btn'
                >
                  Confirm
                </button>
              </div>
            }
          />
          <Modal
            isOpen={this.state.archiving}
            contentLabel='Delete this 360'
            onClose={() => this.setState({ archiving: false })}
            content={
              <div>
                <h2>Are you sure you want to archive this {this.props.current360.type}?</h2>
                <p>Click confirm below to move to {current360.title} the Archive</p>
                <button
                  onClick={(e) => {
                    this.confirmArchive(e);
                  }}
                  className='btn'
                >
                  Confirm
                </button>
              </div>
            }
          />
          <Modal
            isOpen={this.state.unarchiving}
            contentLabel='Unarchive this 360'
            onClose={() => this.setState({ unarchiving: false })}
            content={
              <div>
                <h2>Are you sure you want to Unarchive this {this.props.current360.type}?</h2>
                <p>Click confirm below to restore {current360.title} from the Archive</p>
                <button
                  onClick={(e) => {
                    this.confirmUnarchive(e);
                  }}
                  className='btn'
                >
                  Confirm
                </button>
              </div>
            }
          />
          <Modal
            isOpen={this.state.editing}
            contentLabel='Add a new 360'
            onClose={() => this.setState({ editing: false })}
            content={
              <div>
                <h2>Edit {this.state.new360Type} Project</h2>
                <form onSubmit={this.confirmEdit}>
                  <label htmlFor='title' className='f6 b db mb2'>
                    Project Name
                  </label>
                  <input
                    required
                    ref={(input) => (this.title = input)}
                    aria-describedby='name-desc'
                    className='input-reset ba b--black-20 pa2 mb4 db w-100'
                    type='text'
                    placeholder='Edit your project name'
                    defaultValue={this.state.current360.title}
                  />
                  <button className='btn' disabled={this.state.settingUpProject} type='submit'>
                    Submit
                  </button>
                </form>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}
//

export default List360;
